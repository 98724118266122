import React, { useContext } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { languages } from "../../languages";
import { LanguageContext } from "../../languagesContext";
const Header1 = ({ activeItem }) => {
  const { language, setLanguage } = useContext(LanguageContext);
  const selectedLanguage = languages[language];
  const markets = [
    {
      name: selectedLanguage.landing.Forex,
      navigate: "/markets/forex",
    },
    {
      name: selectedLanguage.landing.Crypto,
      navigate: "/markets/crypto",
    },
    {
      name: selectedLanguage.landing.Stocks,
      navigate: "/markets/stocks",
    },
    {
      name: selectedLanguage.landing.CFDs,
      navigate: "/markets/cfds",
    },
    {
      name: selectedLanguage.landing.Futures,
      navigate: "/markets/futures",
    },
    {
      name: selectedLanguage.landing.Commodities,
      navigate: "/markets/commodities",
    },
  ];
  return (
    <>
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="navigation">
                <Navbar bg="light" expand="lg">
                  <Link className="navbar-brand" to={"/"}>
                    <img src={require("./../../images/logo.png")} alt="" />
                  </Link>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse>
                    <Nav className="ms-auto">
                      <Nav.Item>
                        <Link
                          className={`nav-Nav.link ${
                            activeItem === "home" && "active"
                          }`}
                          to="/"
                        >
                          {selectedLanguage.landing.Home}
                        </Link>
                      </Nav.Item>
                      <NavDropdown title="Markets" id="basic-nav-dropdown">
                        {markets.map((market) => (
                          <div key={market.name}>
                            <Link to={market.navigate}>{market.name}</Link>
                          </div>
                        ))}
                      </NavDropdown>
                      <Nav.Item>
                        <Link
                          className={`nav-Nav.link ${
                            activeItem === "about-us" && "active"
                          }`}
                          to="/about-us"
                        >
                          {selectedLanguage.rest.AboutUs}
                        </Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link
                          className={`nav-Nav.link ${
                            activeItem === "pricing" && "active"
                          }`}
                          to="/pricing"
                        >
                          {selectedLanguage.landing.AccountTypesTitle}
                        </Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link
                          className={`nav-Nav.link ${
                            activeItem === "privacy-policy" && "active"
                          }`}
                          to="/privacy-policy"
                        >
                          {selectedLanguage.landing.privacyPolicy}
                        </Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link
                          className={`nav-Nav.link ${
                            activeItem === "signup" && "active"
                          }`}
                          to="/signup"
                        >
                          {selectedLanguage.rest.Register}
                        </Link>
                      </Nav.Item>
                      <div className="signin-btn">
                        <Link className="btn btn-primary ms-3" to={"/signin"}>
                          {selectedLanguage.rest.SignIn}
                        </Link>
                      </div>
                      <div
                        className="d-flex gap-1 "
                        style={{ marginLeft: "5px" }}
                      >
                        <select
                          onChange={(e) => setLanguage(e.target.value)}
                          className="form-select"
                          aria-label="Default select example"
                          style={{ color: "black" }}
                        >
                          <option selected value="English">
                            English
                          </option>
                          <option value="French">French</option>
                          <option value="German">German</option>
                        </select>
                      </div>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header1;
